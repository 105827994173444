import { IDataset } from '@/src/apis/type/Dataset'
import { Card } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import useGetDatasets from 'src/hooks/queries/useGetDatasets'
import DatasetListTable from './DatasetListTable'
import { selectedDataState, userInfoState } from './store/dataset/atom'
import { analysisResponseAtom } from './store/response/atoms'
import './style/data-analysis-style.css'

const DatasetList = () => {
  const { t } = useTranslation()
  const selectedData = useRecoilValue(selectedDataState)
  const setUserInfo = useSetRecoilState(userInfoState)
  const resetAnalysisResponse = useResetRecoilState(analysisResponseAtom)

  const { data } = useGetDatasets(localStorage.getItem('userId'))
  const [dataArr, setDataArr] = useState([])

  useEffect(() => {
    resetAnalysisResponse()
    setUserInfo({ user_id: localStorage.getItem('userId'), com_id: localStorage.getItem('companyId') })
  }, [])

  useEffect(() => {
    if (data) {
      const updatedData = data.data.map((item: IDataset, index: number) => ({
        ...item,
        key: index.toString(),
      }))

      setDataArr(updatedData)
    }
  }, [data])

  const DatasetDetails = ({ record }: { record: Partial<IDataset> }) => {
    return (
      <Card type="inner" size="small" title={t('Dataset Details')} extra={<a href="#">{t('More')}</a>}>
        <p>
          <span>{t('Created')} :</span> {record.create_date}
        </p>
        <p>
          <span>{t('Type')} :</span> {record.is_classification ? t('Classification') : t('Regression')}
        </p>
        {record.target_y && (
          <p>
            <span>{t('Target')} :</span> {record.target_y}
          </p>
        )}
        {record.descr && (
          <p>
            <span>{t('Description')} :</span> {record.descr}
          </p>
        )}
      </Card>
    )
  }

  return (
    <>
      <DatasetListTable data={dataArr} />
      <div className="mt-4 rounded-xl">
        <DatasetDetails record={selectedData} />
      </div>
    </>
  )
}

export default DatasetList
